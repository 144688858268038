@import 'src/styles/imports';

.Home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //TODO: Remove background color when there are more apps
  background: linear-gradient(360deg, $blue-700 -50%, $blue-500 120%);
}
