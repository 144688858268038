//Blue
$blue-200: #3078cf; // Toggle button background
$blue-500: #204f88; //CTAs, Input Focus Border
$blue-600: #1d487c;
$blue-700: #183c67;
$blue-800: #133053; //Button on Hover
$blue-900: #0f243e;
$blue-tinted-000: #f4f6f9;
$blue-tinted-100: #e8eef4; //Input Focus Background
$blue-tinted-200: #c5d2df;
$blue-tinted-300: #a1b6ca;

//Blue

$blue-tinted-100: #e8eef4; //Input Focus Background
$blue-tinted-200: #c5d2df;
$blue-tinted-300: #a1b6ca;

//Status
$yellow-tinted-suggested: #fefbf0;
$green-tinted-success: #f5f9f5;
$green-success: #38853c;
$red-attention: #f44336;
$red-tinted-attention: #fff7f6;
$yellow-warning: #ff9800;
$blue-tinted-complete: #f4f6f9;
$purple-priorstudy: #604088;
$purple-tinted-priorstudy: #f7f5f9;

// Shades
$black: #1a1a1a;
$grey-900: #333333; //Text Default
$grey-800: #747474; //Text Secondary
$grey-300: #c6c6c6; //Text Disabled
$grey-200: #d6d6d6; //Input Outline
$grey-100: #e8e8e8; //Key Line, Browsing Panel Card Border
$grey-background: #fafafa; // Browsing Panel Background
$white: #fff;

// Shadow

$elevationLevel1: 0 4px 20px rgba(#000, 0.1);
$elevationLevel2: 0 2px 12px rgba(#000, 0.2);
