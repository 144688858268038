@import 'src/styles/imports';

.guides {
  display: grid;
  grid-template-columns: 1fr 25vw;
  overflow: hidden;
  background-color: $grey-background;
  padding-top: 60px;
  width: 100%;
  height: 100%;

  .display {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    padding: 2.5rem;
  }
  .sidebar {
    overflow-y: scroll;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
