@import 'src/styles/imports';

.nav {
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  border-top: $grey-100 solid 1px;
  padding: 2.5rem 0;
  .link {
    a {
      text-decoration: none;
      display: grid;
      grid-template-columns: auto auto;
      align-content: center;
      grid-column-gap: 5px;
      color: $blue-500;
      flex-wrap: nowrap;
      font-weight: 500;
      svg {
        align-self: center;
        width: 16px;
        height: 16px;
      }
    }
  }
  .firstLink {
    margin-left: auto;
  }
}
