@import 'src/styles/imports';

.FAQs {
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  background-color: $grey-background;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    max-width: 960px;
    h1 {
      margin-bottom: 2rem;
    }
    .list {
      list-style: none;
      display: grid;
      grid-row-gap: 20px;
      padding: 0;
      margin: 0;

      p {
        margin: 0;
      }
    }
  }
}
