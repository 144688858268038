@import 'src/styles/imports';

.sidebar {
  min-height: 100%;
  background-color: $white;
  border-left: 1px solid $grey-100;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.25rem 0;
    margin: 0.25rem 0;
  }
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
}
