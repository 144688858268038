@import 'src/styles/imports';

.collapsible {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    &:hover {
      cursor: pointer;
    }
    .toggle {
      transition: all 0.5s ease-in-out;
      transform-origin: center;
    }
  }

  .list {
    height: 100%;
    transition: all 0.5s ease-in-out;

    li {
      padding: 10px 30px;
      color: $grey-800;
      &.active {
        color: $grey-900;
        font-weight: 600;
        &::before {
          content: '';
          z-index: 2;
          position: absolute;
          left: 0rem;
          top: 0;
          height: 100%;
          width: 6px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: $blue-500;
        }
      }
    }
  }
}
