@import 'src/styles/imports';

.questionCard {
  width: 100%;
  background-color: $white;
  border-radius: 0.25rem;
  border: 1px solid $grey-100;
  padding: 20px;
  cursor: pointer;
  .header {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    svg {
      flex-shrink: 0;
      stroke: $blue-500;
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
  .answer {
    transition: all 0.3s ease-in-out;
    color: $grey-800;
    margin-bottom: 0;
  }
}
