@import 'src/styles/imports';

.Banner {
  width: 100%;
  height: 300px;
  max-width: 960px;
  // background-color: $blue-700;
  border-radius: 0.5rem;
  padding: 3rem;
  h1,
  p {
    color: $white;
  }
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  .appLogo {
    position: absolute;
    left: 55%;
    //TODO: Standardise logo size
    top: calc(50% - 150px);

    svg {
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      border-radius: 1rem;
      top: 40%;
      width: 35%;
      height: 55%;
      left: 40%;
      background-color: $blue-800;
    }
  }

  .links {
    margin-top: auto;
    display: grid;
    grid-template-columns: min-content min-content;
    grid-column-gap: 10px;

    a {
      @extend %primaryButtonWhite;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
