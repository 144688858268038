@import 'src/styles/imports';

.article {
  height: fit-content;
  top: 0;
  width: 100%;
  max-width: 960px;
  margin-bottom: 2rem;

  hr {
    display: none;
  }

  img {
    border-radius: 0.5rem;
    box-shadow: $elevationLevel1;
    max-width: 100%;
    max-height: 35vh;
  }

  //TODO: Try to avoid iframes
  iframe {
    background-color: $grey-100;
    border-radius: 0.5rem;
    border: none;
    margin-bottom: 2rem;
    width: 640px;
    height: 480px;
    max-width: 100%;
  }

  h1 {
    font-size: 1rem * 2;
    line-height: 150%;
    border-bottom: 1px solid $grey-100;
    margin: 0 0 2rem;
  }

  h2 {
    font-size: 1rem * 1.75;
    line-height: 150%;
    border-bottom: 1px solid $grey-100;
    margin: 0 0 2rem;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 150%;
    border-bottom: 1px solid $grey-100;
    margin: 0 0 2rem;
  }

  ul {
    margin: 0 0 2rem;
  }
  ol {
    margin: 0 0 2rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}
