@import 'src/styles/imports';

.tabs {
  width: 100%;
  margin-bottom: 1rem;
  .list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 10px;
      margin: 0 10px 10px 0;
      padding: 0.25rem 1rem;
      background-color: $grey-100;
      border-radius: 99px;
      font-size: 1rem;
      border: none;
      white-space: nowrap;

      &.active {
        background-color: $blue-500;
        color: $white;
      }

      &:hover {
        cursor: pointer;
        background-color: $blue-500;
        color: $white;
      }
    }
  }
}
