@import 'src/styles/imports';
.error {
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  background-color: $grey-background;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    max-width: 960px;
    h1 {
      margin-bottom: 2rem;
    }

    button {
      @extend %primaryButton;
    }
  }
}
