@import 'src/styles/imports';

.vote {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 960px;
  justify-content: flex-start;

  .votebuttons {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: min-content min-content auto;
    width: 100%;
    margin-bottom: 2.5rem;
  }

  button {
    @extend %secondaryButton;
  }
  .voted {
    background-color: $blue-tinted-100;
  }

  .feedback {
    margin-bottom: 2.5rem;
    a {
      text-decoration: none;
      color: $blue-500;
      font-weight: 500;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .hidden {
    display: 0;
  }
}
