%primaryButton {
  padding: 0.5rem 2rem;
  background: $blue-500;
  border-radius: 99px;
  color: $white;
  font-size: 1rem;
  border: none;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: $blue-800;
  }
}

%primaryButtonWhite {
  @extend %primaryButton;
  color: $blue-500;
  background: $white;
  &:hover {
    cursor: pointer;
    background-color: $blue-tinted-100;
  }
}

%secondaryButton {
  padding: 0.5rem 2rem;
  background: $white;
  border-radius: 99px;
  color: $blue-500;
  font-size: 1rem;
  border: 1px solid $blue-500;
  white-space: nowrap;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;

  svg {
    width: 16px;
    height: 16px;
    stroke: $blue-500;
  }

  &:hover {
    cursor: pointer;
    background-color: $blue-tinted-100;
  }

  &.active {
    background-color: $blue-tinted-100;
  }
}

%textButton {
  text-decoration: none;
  color: $blue-500;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    color: $blue-tinted-300;
  }
}
