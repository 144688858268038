@import 'src/styles/imports';

.topnav {
  height: 60px;
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid $grey-100;
  position: absolute;
  z-index: 9;
  background-color: #fff;

  a {
    text-decoration: none;
  }

  @media print {
    display: none;
  }
}

.left,
.right {
  display: flex;
  align-items: center;
}

// left

.left {
  svg {
    height: 30px;
  }
}

.divider {
  margin: 0 20px;
  height: 30px;
  width: 1px;
  background-color: $grey-100;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

// right
